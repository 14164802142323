import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { useGetAmberToken } from '../../hooks/useAmberContractFunctions';
import { useAmberContract } from '../../hooks/initialContract';
import Link from 'next/link';
import { useActiveAccount } from 'thirdweb/react';
import { weiToEther } from '../../utils/global';
import { BigNumber } from 'ethers';

export default function AmberStats() {
    const [amberCoin, setAmberCoin] = useState<string | null>(null);
    const [amberFullCoin, setAmberFullCoin] = useState<string | null>(null);
    const account = useActiveAccount();
    const amber = useAmberContract();

    const { data: amberToken, refetchAmberNumber } = useGetAmberToken(amber, account?.address || '');

    useEffect(() => {
        async function loadAmberToken() {
            setAmberCoin(null);
            await refetchAmberNumber();

            if (amberToken) {
                const amberCoins = BigNumber.from(amberToken);
                const etherValue = weiToEther(amberCoins);
                const roundedValue = parseFloat(etherValue).toFixed(0);
                setAmberFullCoin(roundedValue);

                const formattedValue = formatAmberCoin(Number(roundedValue));
                setAmberCoin(formattedValue);
            }
        }
        loadAmberToken();
    }, [amberToken, account]);

    function formatAmberCoin(value: any) {
        if (value <= 9999) return value;
        if (value >= 1000 && value <= 999999) {
            return `${(value / 1000).toFixed(0)}K`;
        }
        if (value >= 1000000) {
            return `${(value / 1000000).toFixed(0)}M`;
        }
        return value.toString();
    }

    return (
      <>
        {amberCoin && account && (
        <Link href="/more/swap/" className="relative group">
          <div className="bg-surfaceTop flex flex-row items-center px-4 min-w-[180px] h-[50px] gap-2 rounded-md shadow-lg group-hover:bg-opacity-100 bg-opacity-70 cursor-pointer">
            <Image
                src={'/icons/diamond.svg'}
                alt="Diamond icon"
                className="aspect-[1/1]"
                width={32}
                height={32}
                loading="lazy"
            />
            <div className="relative text-white text-[14px] 3xl:text-[18px] font-ubuntu font-[700] text-center">
              {amberCoin} AMBER
              {/* Tooltip */}
              <div className="absolute left-1/2 transform -translate-x-1/2 top-full mt-4 px-3 py-1 bg-black text-white text-[18px] rounded-md shadow-lg opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                {amberFullCoin}
              </div>
            </div>
          </div>
        </Link>
        )}
      </>
    );
}