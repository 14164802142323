// components/Layout.tsx
import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useAppSelector, useAppDispatch } from '../store/store';
import Loading from './loader/Loading';
import MainDialog from './dialog/mainDialog';
import LoginDialog from './dialog/loginDialog';
import { openModal } from '../store/Login/modalSlice';

// Use dynamic imports if needed
const Header = dynamic(() => import('./blocks/header'));
const Footer = dynamic(() => import('./blocks/footer'));

interface LayoutProps {
    children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
    const isLoading = useAppSelector((state) => state.loader.loading);
    const alerts = useAppSelector((state) => state.alert.alerts);
    const modalState = useAppSelector((state) => state.modal);
    const dispatch = useAppDispatch();
    const router = useRouter();
    const [prefilledReferral, setPrefilledReferral] = useState<string | null>(null);

    const checkWalletTokenExists = (): boolean => {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key && key.startsWith('walletToken-')) {
                return true;
            }
        }
        return false;
    };

    useEffect(() => {
        if (router.query.referral && checkWalletTokenExists()) {
            router.push('/profile');
        }
    }, [router.query, router]);

    useEffect(() => {
        if (router.query.referral && !checkWalletTokenExists()) {
            const referral = router.query.referral as string;
            setPrefilledReferral(referral);
            dispatch(openModal('register'));
            router.replace(router.pathname, undefined, { shallow: true });
        }
    }, [router.query, dispatch, router.pathname]);

    return (
      <>
        <Header />
        {children}

        {alerts.map((alert) => (
          <MainDialog key={alert.id} alertData={alert} />
        ))}

        {modalState.isOpen && modalState.type === 'login' && (
        <LoginDialog prefilledReferral={null} />
        )}
        {modalState.isOpen && modalState.type === 'register' && (
        <LoginDialog prefilledReferral={prefilledReferral} />
        )}

        <Loading isLoading={isLoading} />
        <Footer />
      </>
    );
}