import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useActiveAccount } from 'thirdweb/react'

export default function ProfileMenu() {
    const account = useActiveAccount();
    return (
      <>
        {account?.address && (
        <Link href='/profile' className='relative group lg:w-12 lg:h-12 w-8 h-8 rounded-full bg-profileBack text-white cursor-pointer'>
          <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:w-6 lg:h-6 w-4 h-4'>
            <Image
                src={'/icons/profile.svg'}
                fill
                alt='Logo'
                loading='lazy'
            />
          </div>
        </Link>
        )}
      </>
    );
}
