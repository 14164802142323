import React from 'react';
import Image from 'next/image';
import { AlertStatus, AlertType } from '../../utils/alert.type';
import { useAppDispatch } from '../../store/store';
import { deleteAlerts } from '../../store/alert/alertSlice';
import MainButton from '../buttons/mainButton';
interface DialogProps {
    alertData: AlertType;
}

export default function MainDialog({ alertData }: DialogProps) {
    const dispatch = useAppDispatch();

    if (!alertData.message) {
        return null;
    }

    return (
      <>
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>

        <div className={`${
                alertData.type === AlertStatus.error
                    ? 'border-errorBorder bg-errorBorderBack'
                    : 'border-stakingBorder bg-stakingBack'
            } absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 border-4 rounded-2xl px-4 p-8 lg:px-8 w-full md:max-w-[450px] h-[200px]`}
        >
          <div className="absolute top-0 right-0 p-1">
            <button
                  title="close"
                  onClick={() => {
                      dispatch(deleteAlerts());
                  }}
                  className="relative w-8 h-8 transition-transform transform hover:scale-110 hover:filter active:filter group"
              >
              <Image
                  src="/icons/cross.svg"
                  alt="Close"
                  width={32}
                  height={32}
                  loading="lazy"
              />
            </button>;
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="text-white text-[20px] lg:text-[24px] font-georgia font-[700] leading-tight">
              {alertData.title}
            </div>
            <div className="text-white text-[16px] font-ubuntu font-[400] leading-tight max-w-screen-sm text-center mt-2 mb-2">
              <p>{alertData.message}</p>
            </div>
            <div className="flex flex-col items-center justify-center mt-2 gap-4 w-full">
              <div className="relative w-full h-[2px]">
                <Image
                    src={`${
                        alertData.type === AlertStatus.error
                            ? '/elements/bottomLineRed.webp'
                            : '/elements/bottomLineBlue.webp'
                    }`}
                    alt="Background line"
                    fill
                    loading="lazy"
                />

              </div>

              <MainButton
                    borderColor='white'
                    stopColorOne="#006400"
                    stopColorTwo="#228B22"
                    className='w-[100px] py-2 text-white rounded text-[14px]'
                    onClick={() => dispatch(deleteAlerts())}
                >
                Ok
              </MainButton>
            </div>
          </div>
        </div>
      </>
    );
}