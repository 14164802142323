import '../styles/globals.css';
import React from 'react';
import type { AppProps } from 'next/app';
import { ThirdwebProvider } from 'thirdweb/react';

import { Provider } from 'react-redux';
import { store } from '../store/store';
import Head from 'next/head';
import {Metadata} from 'next';
import Layout from '../components/layout';

export const metadata: Metadata = {
    title: 'Decision Crypto Game',
    description:
        'Innovative card game that merges NFTs, Battle for Crypto, Staking, Mining, DeFi, & GameFi.',
};
export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ThirdwebProvider>
      <Provider store={store}>
        <Head>
          <title>Decision Card Game</title>
          <meta name="description" content="Innovative card game that merges NFTs, Battle for Crypto, Staking, Mining, DeFi, & GameFi." />
        </Head>

        <Layout>
          <Component {...pageProps} />
        </Layout>
          
      </Provider>
    </ThirdwebProvider>
  );
}
