import {BigNumber, ethers} from 'ethers';

export function makeAddressShort(address: string, length = 4) {
  return `${address.substring(0, length + 2)}…${address.substring(
    address.length - length
  )}`;
}

export const weiToEther = (amount: BigNumber): string => {
  return ethers.utils.formatEther(amount);
};

export const etherToWei = (amount: string): BigNumber => {
  return ethers.utils.parseEther(amount);
};
