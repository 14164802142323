import React from 'react';
import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import AmberStats from './amberStats';
import {footerMenu} from '../../utils/menu.type';
import { ConnectButton, useActiveAccount } from 'thirdweb/react';
import {client} from '../../utils/client';
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/Login/modalSlice';

import axios from 'axios';

export const MobileMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const account = useActiveAccount();
  const BASE_URL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:3000';
  const dispatch = useDispatch();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLoginClick = () => {
    setIsOpen(false);
    dispatch(openModal('login'));
  };


  const handleDisconnect = async () => {
    try {
      const refreshToken = localStorage.getItem('refresh_token');

      if (!refreshToken) {
        console.warn('Refresh token not found');
        return;
      }

      await axios.post(`${BASE_URL}/logout`, { refreshToken });

      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');

    } catch (error: any) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <>
      <button className='xl:hidden flex top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none z-50' onClick={toggleMenu}>
        <div className='relative w-10 h-10 -ml-2'>
          <Image
            src='/icons/menu.svg'
            fill
            alt='Menu icon'
            loading='lazy'
        />
        </div>
      </button>

      {isOpen && (
        <div className='fixed inset-0 flex items-center justify-start z-50'>
          <div className='w-3/4 lg:w-1/2 h-full bg-surfaceLow shadow-2xl p-4'>
            <div className='relative'>
              <button className='absolute right-0 text-gray-500 hover:text-gray-700 focus:outline-none -mt-2 -mr-5 lg:mt-2' onClick={toggleMenu}>
                <Image
                  src='/icons/cross.svg'
                  style={{ width: 'auto', height: 'auto' }}
                  width={24}
                  height={24}
                  alt='Close icon'
                  loading='lazy'
              />
              </button>
            </div>

            <div className='mt-10 lg:mt-20 flex flex-col gap-4'>
              <AmberStats/>
              <div onClick={(e) => {
                if (!account) {
                  handleLoginClick();
                  e.stopPropagation();
                }
              }}
                   style={{
                     pointerEvents: 'auto',
                     cursor: account ? 'default' : 'pointer',
                   }}
              >
                <div style={{
                  pointerEvents: account ? 'auto' : 'none',
                  cursor: account ? 'pointer' : 'not-allowed',
                }}
                >
                  <ConnectButton
                      client={client}
                      onDisconnect={handleDisconnect}
                  />
                </div>
              </div>

            </div>

            <div className='flex flex-col items-start gap-8 mt-8'>
              {footerMenu.map((item) => (
                <Link
                      href={item.href}
                      key={item.name}
                      onClick={toggleMenu}
                      className='text-white text-[20px] font-ubuntu font-[700]'
                  >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileMenu;
