import { GameType } from './game.type';

export enum AlertStatus {
    start = 'start',
    cancel = 'cancel',
    pending = 'pending',
    waiting = 'waiting',
    accepted = 'accepted',
    error = 'error'
}

export interface AlertType {
    id: number;
    message: string;
    title: string;
    type?: AlertStatus
}
