import {useReadContract} from 'thirdweb/react';

export const useGetAmberToken = (amber: any, address: string) => {
  const { data, isLoading, error, refetch: refetchAmberNumber} = useReadContract({
    contract: amber,
    method: 'function balanceOf(address account) external view returns (uint256)',
    params: [address],
  });

  return { data, isLoading, error, refetchAmberNumber };
};
