import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GameType } from '../../utils/game.type';
import { AlertStatus } from '../../utils/alert.type';

interface Alert {
    id: number
    message: string;
    title: string;
    type?: AlertStatus
}

interface AlertState {
    alerts: Alert[]
}

const initialState: AlertState = {
    alerts: []
}

export const AlertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        addAlert: (state, action: PayloadAction<{message: string, title: string, type?: AlertStatus}>) => {
            state.alerts.push({
                id: state.alerts.length,
                message: action.payload.message,
                title: action.payload.title,
                type: action.payload.type
            })
        },
        deleteAlert: (state, action: PayloadAction<{index: number}>) => {
            state.alerts.splice(state.alerts.findIndex(function(item){
                return item.id === action.payload.index;
            }), 1);
        },

         deleteAlerts: (state) => {
            state.alerts = []
        }
        
    }
})


export default AlertSlice.reducer;

export const { addAlert, deleteAlert, deleteAlerts } = AlertSlice.actions;