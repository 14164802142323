// store/login/modalSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalState {
    isOpen: boolean;
    type: 'login' | 'register' | null;
}

const initialState: ModalState = {
    isOpen: false,
    type: null,
};

export const ModalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal: (state, action: PayloadAction<'login' | 'register'>) => {
            state.isOpen = true;
            state.type = action.payload;
        },
        closeModal: (state) => {
            state.isOpen = false;
            state.type = null;
        },
    },
});

export const { openModal, closeModal } = ModalSlice.actions;

export default ModalSlice.reducer;