import React, { ReactNode } from 'react';
import Image from 'next/image';

interface ButtonProps {
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  borderColor: string;
  stopColorOne: string;
  stopColorTwo: string;
  className?: string;
  icon?: string;
}

export default function MainButton({
  children,
  borderColor,
  stopColorOne,
  stopColorTwo,
  className,
  icon,
  onClick,
  ...props
}: ButtonProps) {
  const colorId = (borderColor + stopColorOne + stopColorTwo).replace('#', '');
  return (
    <button
      className={`relative text-white text-center font-georgia font-[700] cursor-pointer group ${className}`}
      onClick={onClick}
      {...props}
    >
      <div className='absolute inset-0 flex justify-center items-center'>
        <svg
          width='100%'
          height='100%'
          viewBox='0 0 200 55'
          preserveAspectRatio='none'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            x='0.5'
            y='0.5'
            width='99%'
            height='54'
            rx='7.5'
            fill={`url(#paint0_radial_3_2339${colorId})`}
          />

          <path
            d='M54.9404 53.3931L70.7125 54.8648L9 54.4482L21.5759 52.2244L47.4694 54.4482L54.9404 53.3931Z'
            fill={borderColor}
          />
          <path
            d='M75.6775 1.99131L35.2729 0.689389L193.258 0.442025L161.11 2.801L94.781 0.855893L75.6775 1.99131Z'
            fill={borderColor}
          />
          <rect
            x='0.5'
            y='0.5'
            width='99%'
            height='54'
            rx='7.5'
            stroke={borderColor}
          />
          <defs>
            <radialGradient
              id={`paint0_radial_3_2339${colorId}`}
              cx='0'
              cy='-0.3'
              r='1'
              gradientUnits='userSpaceOnUse'
              gradientTransform='translate(100 27.5) rotate(90) scale(27.5 100)'
            >
              <stop stopColor={stopColorOne} />
              <stop offset='1' stopColor={stopColorTwo} />
            </radialGradient>
          </defs>
        </svg>
      </div>

      <div className='absolute inset-0 flex justify-center items-center'>
        <div className='w-full h-full group-hover:opacity-[0.3] opacity-10 group-focus:opacity-40 bg-buttonGradient transition duration-200 ease-in-out'></div>
      </div>
      <div className='relative flex gap-2 items-center justify-center'>
        {icon && (
          <div className='relative w-4 h-4 3xl:w-6 3xl:h-6 flex'>
            <Image src={'/icons/' + icon} fill alt='Logo' loading='lazy' />
          </div>
        )}
        {children}
      </div>
    </button>
  );
}
