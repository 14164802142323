// store/index.ts
import { configureStore } from '@reduxjs/toolkit';

import { AlertSlice } from './alert/alertSlice';
import { LoadingSlice } from './loading/loadingSlice';
import {ModalSlice} from './Login/modalSlice';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';


export const store = configureStore({
    reducer: {
        alert: AlertSlice.reducer,
        loader: LoadingSlice.reducer,
        modal: ModalSlice.reducer
    }
});

// Typed hooks for usage throughout the app
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;