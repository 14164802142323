import React from 'react';

interface LoadingOverlayProps {
  isLoading: boolean;
}

export default function Loading({ isLoading }: LoadingOverlayProps) {
  if (!isLoading) {
    return null;
  }

  return (
    <div className='fixed top-0 left-0 right-0 bottom-0 z-50 flex flex-col items-center justify-center bg-black bg-opacity-50'>
      <div className='hidden lg:block relative w-20 h-12'>
        <video loop autoPlay muted playsInline>
          <source src='/logo/logo.mov' type='video/quicktime' />
          <source src='/logo/logo.webm' type='video/webm' />
        </video>
      </div>
      <div className='text-white'>
        <p>Loading...</p>
      </div>
    </div>
  );
}
