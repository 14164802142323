// components/blocks/header.tsx
import React from 'react';
import AmberStats from '../elements/amberStats';
import { ConnectButton, useActiveAccount } from 'thirdweb/react';
import { MobileMenu } from '../elements/mobileMenu';
import Logo from '../elements/logo';
import Menu from '../elements/menu';
import ProfileMenu from '../elements/profileMenu';
import { client } from '../../utils/client';
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/Login/modalSlice';
import axios from 'axios';

const BASE_URL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:3000';

export default function Header() {
    const account = useActiveAccount();
    const dispatch = useDispatch();

    const handleLoginClick = () => {
        dispatch(openModal('login'));
    };

    const handleDisconnect = async () => {
        try {
            const refreshToken = localStorage.getItem('refresh_token');

            if (!refreshToken) {
                console.warn('Refresh token not found');
                return;
            }

            await axios.post(`${BASE_URL}/logout`, { refreshToken });

            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');

        } catch (error: any) {
            console.error('Error during logout:', error);
        }
    };

    return (
      <div className="lg:fixed top-0 left-0 right-0 lg:p-4 z-50">
        <div className="hidden xl:flex justify-between w-full bg-surfaceNormal p-2 px-4 xl:rounded-xl bg-opacity-90 backdrop-blur-sm">
          <Logo />
          <div className="flex 2xl:flex justify-center items-start cursor-pointer">
            <Menu />
          </div>
          <div className="flex 2xl:flex-1 items-center justify-end gap-4">
            <AmberStats/>

            <div onClick={(e) => {
                if (!account) {
                    handleLoginClick();
                    e.stopPropagation();
                }
            }}
                 style={{
                     pointerEvents: 'auto',
                     cursor: account ? 'default' : 'pointer',
                 }}
            >
              <div style={{
                      pointerEvents: account ? 'auto' : 'none',
                      cursor: account ? 'pointer' : 'not-allowed',
                  }}
              >
                <ConnectButton
                      client={client}
                      onDisconnect={handleDisconnect}
                />
              </div>
            </div>
            <ProfileMenu/>
          </div>
        </div>
        <div className="flex xl:hidden justify-between items-center w-full bg-surfaceNormal p-2 lg:rounded-xl">
          <MobileMenu/>
          <ProfileMenu/>
        </div>
      </div>
    );
}